import React, { Component} from 'react'
import { HashLink as Link } from 'react-router-hash-link'

import logo from './images/logo.svg'
import menuIcon from './images/menuIcon.svg'


class Navbar extends Component {
    render = () => {
        return (
                <nav className="navbar">
                    <img src={logo} alt="Natural Marine"/>
                    <ul className="navbar__items">
                        <Link smooth to="/#home">
                            <li className="navbar__item">
                                Home
                            </li>
                        </Link>
                        <Link smooth to="/#our-materials">
                            <li className="navbar__item">
                                Materials
                            </li>
                        </Link>
                        <Link smooth to="/#contact-us">
                            <li className="navbar__item">
                                Contact Us
                            </li>
                        </Link>                    
                    </ul>
                    <div className="navbar__open-mobile-menu-button">
                            <img src={menuIcon} alt="Open mobile menu button"/>
                        </div>
                </nav>
        )
    }
}

export default Navbar