import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navbar from 'Navbar';
import SectionsPage from 'SectionsPage';
import Datasheet from 'Datasheet';
import Footer from 'Footer';
import './App.scss';
import CZ from 'images/cz.png';
import CAZ from 'images/caz.png';
import AZS27 from 'images/azs27.png';

const materials = [
  {
    shortName: "CZ",
    name: "ZIRCON SILICATE BEADS",
    image: CZ,
    description: "Zircon Silicate base beads are tailor made grinding media of wide range of minerals.",
    composition: [
      {
        name: "ZrO2+HfO2",
        typical: '≥55%',
      },
      {
        name: "SiO2",
        typical: '≤38%',

      },
    ],
    misc: {
      density: '≥4 gm/cm3',
      bd: '≥2.4 g/cm3',
      sizing: "0,4 – 7 mm",
      hardness: '≥900',
      sphericity: '≥92%',
      packaging: '1000 kgs/bag | 2000 kgs/bag'
    }
  },
  {
    shortName: "CZS",
    name: "ZIRCONIA TOUGHENED ALUMINA BEADS",
    image: CAZ,
    description: "Zirconia toughened Alumina base beads is used for grinding in wide range of applications in inks, paints and mining industries.",
    composition: [
      {
        name: "ZrO2+HfO2",
        typical: '≥5%',
      },
      {
        name: "Al2O3",
        typical: '≥75%',
      },
    ],
    misc: {
      density: '≥4 gm/cm3',
      bd: '≥2.3 g/cm3',
      sizing: "0,4 – 7 mm",
      hardness: '≥1200',
      sphericity: '≥92%',
      packaging: '1000 kgs/bag | 2000 kgs/bag'
    }
  },
  {
    shortName: "AZS27",
    name: "ALUMINA ZIRCON SILICATE",
    image: AZS27,
    description: "AZS27 Alumina Zircon Silicate is pulverized raw material to customer sizing needs used as a compounding raw material in production of opacifiers for ceramics.",
    composition: [
      {
        name: "ZrO2+HfO2",
        typical: '≥27%',
      },
      {
        name: "Al2O3",
        typical: '≥35%',
      },
      {
        name: "SiO2",
        typical: '≥25%',
      },
      {
        name: "Na2O",
        typical: '≤2%',
      },
    ],
    misc: {
      bd: '3.0 g/cm3',
      refractoriness: 1450,
      sizing: '-325 mesh | -200 mesh | 0-1mm | 0-3 mm',
      packaging: '1000 kgs/bag | 1200 kgs/bag'
    }
  }
]

export default function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Route exact path="/" render={(props) => (<SectionsPage materials={materials}></SectionsPage>)} />
        <Route path="/datasheet/:name" render={(routeProps) => <Datasheet {...routeProps} materials={materials}></Datasheet>}></Route>
        <Footer />
      </div>
    </Router>

  );
}

