import React from 'react';
import Material from 'Material';


export default function OurMaterials({ id, materials }) {
    return (
        <section id={id}>
            <h1>Our materials</h1>
            <p>We continuously develop new materials to reduce the cost for our clients and ensure a greener supply chain. Our commitment to preservation of environment helps our partners comply with the evolving relevant legislation worldwide.</p>
            <div className="materials">
                {materials.map((material) => (
                    <Material key={material.shortName} {...material} />
                ))}
            </div>
        </section>
    )
}
