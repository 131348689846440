import React, { useEffect } from 'react';
import arrowBack from 'images/arrowBack.svg';
import { Link, useParams } from 'react-router-dom';

export default function Datasheet({ materials }) {

    const { name } = useParams()

    const material = materials.find(material => material.shortName === name)

    useEffect(() => {
        window && window.scrollTo({ top: 0 });
    }, [])

    return (
        <div className="datasheet">
            <Link to="/">
                <button className="datasheet__back-button">
                    <img src={arrowBack} alt="" />
                    <span>Back</span>
                </button>
            </Link>

            <div className="datasheet__material-name">{material.shortName}</div>
            <div className="datasheet__description">
                {material.description}
            </div>

            <div className="datasheet__facts">
                <div className="datasheet__fact">
                    <div className="datasheet__fact-bold">{material.process}</div>
                    <div className="datasheet__fact-small">Process</div>
                </div>
                <div className="datasheet__fact">
                    <div className="datasheet__fact-bold">BULK DENSITY | {material.density}</div>
                    <div className="datasheet__fact-small">Typical</div>
                </div>
            </div>

            <div className="datasheet__composition">
                <div className="datasheet__composition-title">CHEMICAL COMPOSITION & PHYSICAL ANALYSIS</div>
                <div className="datasheet__facts">
                    {material.composition.map(({ name, typical }) =>
                        <div className="datasheet__fact datasheet__fact--extra-condensed datasheet__fact--row">
                            <div className="datasheet__fact-bold datasheet__fact-bold--space-right">{name}</div>
                            <div className="datasheet__fact-small">{typical}</div>
                        </div>
                    )}
                </div>
            </div>

            <div className="datasheet__facts">
                {Object.entries(material.misc).map(([key, value]) =>
                    <div className="datasheet__fact datasheet__fact--condensed ">
                        <div className="datasheet__fact-bold">{key}</div>
                        <div className="datasheet__fact-small">{value}</div>
                    </div>
                )}
            </div>
        </div>
    )
}