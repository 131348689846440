import React, { Component } from 'react';
import contactUsHeader from 'images/contactUsHeader.svg';

class ContactUs extends Component {
    constructor(props) {
        super();
        this.id = props.id;
    }

    render() {
        return (
            <section id={this.id}>
                <div className="contact-form">
                    <img className="contact-us-header" src={contactUsHeader} alt=""/>
                    <form>
                        <h1>Contact us</h1>
                        <div className="input-group">
                            
                            <input type="text" name="name" id="name_input"/>
                            <label htmlFor="name_input">Name</label>
                        </div>
                        <div className="input-group">
                            <input type="email" name="email" id="email_input"/>
                            <label htmlFor="email_input">Email</label>
                        </div>
                        
                        <div className="input-group input-group--textarea">
                            <textarea id="message_input" name="message" rows="3"></textarea>
                            <label htmlFor="message_input">Message</label>
                        </div>
                        <button className="contact-form__send-button">Send</button>
                    </form>
                </div>
            </section>
        )
    }
}

export default ContactUs;