import React from 'react';
import CZ from 'images/cz.png';
import arrowDown from 'images/arrowDown.svg'
import heroMobileBackground from 'images/heroMobileBackground.svg'
import datasheetArrow from 'images/datasheetArrow.svg'
import { BrowserRouter as Router, Link } from "react-router-dom"
import { HashLink as Hashlink } from 'react-router-hash-link'
import heroBg from 'images/heroBg.png'

export default function Home({ id }) {
    return (
        <section style={{ backgroundImage: `url(${heroBg})` }} id={id}>
            <div className="home-container">
                <h1>Trade of grinding media and raw materials for ceramics industry</h1>
                <div className="hero-material">
                    <img className="hero-material__mobile-background" src={heroMobileBackground} alt="" />
                    <div className="hero-material__text">
                        <h1 className="hero-material__title">Zircon Silicate beads</h1>
                        <p className="hero-material__description">Zircon Silicate base beads are tailor made grinding media of wide range of minerals.</p>
                        <Link to="/datasheet/CZ">
                            <button className="hero-material__open-datasheet-button">
                                <span>Data sheet</span>
                                <img src={datasheetArrow} alt="" />
                            </button>
                        </Link>
                    </div>
                    <div className="hero-material__bottom-section">
                        <img src={CZ} alt="Zircon silicate beads" />
                        <Link to="/datasheet/CZ">
                            <button className="hero-material__open-datasheet-button">
                                <span>Data sheet</span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <Router>
                <Hashlink className="arrow-down" smooth to="/#our-materials">
                    <img src={arrowDown} alt="Navigate to our materials" />
                </Hashlink>
            </Router>
        </section>
    )
}