import React from 'react';
import Home from 'sections/Home';
import OurMaterials from 'sections/OurMaterials';
import ContactUs from 'sections/ContactUs';

export default function SectionsPage({ materials }) {
    return (
        <div>
            <Home id="home" />
            <OurMaterials id="our-materials" materials={materials} />
            <ContactUs id="contact-us" />
        </div>

    )
}