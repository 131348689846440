import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer__content">
                    <div className="footer-item">
                        <div className="footer-item__label">
                            Address
                        </div>
                        <div className="footer-item__text">
                            <p>Unit C, 9/F, Tower B, Billion Centre,</p>
                            <p>1 Wang Kwong Road, Kowloon bay,</p>
                            <p>Kowloon Hong Kong.</p>
                        </div>
                    </div>
                    <div className="footer-item">
                        <div className="footer-item__label">
                            Email
                        </div>
                        <p className="footer-item__text">
                            <a href="mailto:captain@natural-marine.com">captain@natural-marine.com</a>
                        </p>
                    </div>
                    <div className="footer__disclaimer">
                        Natural Marine 2019
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;