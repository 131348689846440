import React, { Component } from 'react';
import materialBackground from './images/materialBackground.svg';
import { Link } from "react-router-dom";

class Material extends Component {
    constructor(props) {
        super(props);
        this.material = props.material;
    }
    
    render() {
        const {name, shortName, image } = this.props
        return (
                <div className="material">
                    <img className="material__background" src={materialBackground} alt=""/>
                    <img className="material__image" src={image} alt={shortName}/>
                    <div className="material__text">
                        <h2 className="material__short-name">{shortName}</h2>
                        <h2 className="material__name">{name}</h2>
                        <Link to={`/datasheet/${shortName}`}>
                            <button>
                                <span>Data sheet</span>
                            </button>
                        </Link>
                    </div>
                </div>                     
        )
    }
}

export default Material;